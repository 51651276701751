import { isEmpty } from 'lodash-es';

const REL_ALTERNATE = 'alternate';
const REL_CANONICAL = 'canonical';

export function useEtherSeo(refSeo) {
    const seoEntry = toRef(refSeo);
    const { public: config } = useRuntimeConfig();

    const seoTitle = computed(() => {
        if (seoEntry.value?.seo?.title) {
            return `${seoEntry.value.seo.title} | ${config?.site?.name}`;
        } else if (seoEntry.value?.title) {
            return `${seoEntry.value.title} | ${config?.site?.name}`;
        }

        return config?.site?.name;
    });

    const seoDescription = computed(() => {
        return seoEntry.value?.seo?.description ??
            seoEntry.value?.description ??
            config?.site?.description;
    });

    const seoImage = computed(() => {
        if (seoEntry.value?.featuredImage?.[0]?.w768) {
            return seoEntry.value.featuredImage[0];
        } else if (seoEntry.value?.image?.[0]?.w768) {
            return seoEntry.value.image[0];
        } else if (seoEntry.value?.imageMobile?.[0]?.w768) {
            return seoEntry.value.imageMobile[0];
        } else if (seoEntry.value?.imageCard?.[0]?.w768) {
            return seoEntry.value.imageCard[0];
        }

        return '';
    });

    const seoSocial = computed(() => seoEntry.value?.seo?.social ?? {});
    const seoFacebook = computed(() => seoSocial.value?.facebook ?? {});
    const seoTwitter = computed(() => seoSocial.value?.twitter ?? {});
    const seoFacebookImage = computed(() => seoFacebook.value?.image ?? {});
    const seoTwitterImage = computed(() => seoTwitter.value?.image ?? {});
    const seoRobots = computed(() => {
        return seoEntry.value?.seo?.advanced?.robots?.map((content) => {
            return { name: 'robots', content };
        }) ?? [];
    });
    const seoCanonical = computed(() => seoEntry.value?.seo?.advanced?.canonical);
    const seoMeta = computed(() => {
        return [
            { hid: 'description', name: 'description', content: seoDescription.value ?? '' },
            // FACEBOOK
            { hid: 'og:type', property: 'og:type', content: 'website' },
            { hid: 'og:title', property: 'og:title', content: seoFacebook.value?.title ?? seoTitle.value },
            { hid: 'og:description', property: 'og:description', content: seoFacebook.value?.description ?? seoDescription.value ?? '' },
            { hid: 'og:image', property: 'og:image', content: seoFacebookImage.value?.w768 ?? seoImage.value?.w768 ?? '' },
            // TWITTER
            { hid: 'twitter:title', property: 'twitter:title', content: seoTwitter.value?.title ?? seoTitle.value },
            { hid: 'twitter:description', property: 'twitter:description', content: seoTwitter.value?.description ?? seoFacebook.value?.description ?? seoDescription.value ?? '' },
            { hid: 'twitter:image', property: 'twitter:image', content: seoTwitterImage.value?.w768 ?? seoFacebookImage.value?.w768 ?? seoImage.value?.w768 ?? '' }
        ];
    });

    const nuxtI18nHead = useLocaleHead({
        addSeoAttributes: {
            canonicalQueries: ['page', 'search']
        },
    });

    const { locales, locale } = useI18n();

    const seoAttributeLinks = computed(() => {
        const i18nLinks = nuxtI18nHead.value?.link ?? [];
        const links = i18nLinks.filter((link) => {
            // If localized urls are provided from the server, remove nuxt generated urls.
            if (link.rel === REL_ALTERNATE && seoEntry.value && seoEntry.value?.localized) {
                return false;
            }

            // filter out localizations we explicitly exclude from config.
            if (link.rel === REL_ALTERNATE) {
                const found = locales.value?.find(l => l.code === link.hreflang);
                return !found?.excludeAsAlternate ?? true;
            }

            if (seoCanonical.value && link.rel === REL_CANONICAL) {
                link.href = seoCanonical.value;
            }

            return true;
        });

        // If localized urls are provided from the server, use those!
        if (!isEmpty(seoEntry.value?.localized)) {
            seoEntry.value?.localized.forEach(({ language, url }) => {
                if (url) {
                    links.push({
                        hid: `i18n-alt-${language}`,
                        href: url.endsWith('/') ? url : `${url}/`,
                        hreflang: language,
                        rel: REL_ALTERNATE
                    });
                }
            });

            // Re-add self-referential link
            links.push(
                i18nLinks.find((link) => {
                    return link.hreflang === locale.value;
                })
            );
        }

        return links;
    });

    const metaData = computed(() => {
        return {
            ...nuxtI18nHead.value,
            title: seoTitle.value,
            meta: [
                ...seoMeta.value,
                ...seoRobots.value,
            ],
            link: seoAttributeLinks.value
        };
    });

    useHead(metaData);
}
